.provider-left {
    width: 50px;
    display: flex;
    align-items: center;
}

.provider-right {
    border: 1px solid grey;
    padding: 10px;
    border-radius: 7px;
    cursor: pointer;
    flex-grow: 1;
}

.provider-right .distance {
    font-weight: bold;
}

div.provider-left svg {
}

label.search-provider {
    display: flex;
    flex-direction: row;
    align-items: center;
}

label.search-provider input {
    font-size: 16px;
}

.requests-table {
    min-height: 600px;
}
