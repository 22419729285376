@import 'variables';
// @import '../../../node_modules/bootstrap/dist/css/bootstrap-utilities.css';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/maps';

@import '../../../node_modules/bootstrap/scss/root';

.vl-common-bootstrap {
  @import 'bootstrap';
}

// In order the this file’s selector precendence to work, it must *wrap* the
// element with the class .vl-common-bootstrap
.vl-common-timeline-bootstrap-overrides {
  @import 'timeline-bootstrap-overrides';
}

.vl-common-add-patient {
  @import 'add-patient';
}
