.addm-container input {
  border: 1px solid #707070;
  border-width: 0 0 1px 0;
  font-size: 14px !important;
}

.addm-container select {
  border: 1px solid #707070;
  border-width: 0 0 1px 0;
}

.addm-container textarea {
  border: 1px solid #707070;
  border-width: 0 0 1px 0;
  height: 35px;
}

.clinician-phone-input input {
  border-bottom: 1px solid rgb(206, 212, 218) !important;
  border-width: 0 0 1px 0;
  height: 32px !important;
}

.addm-select-chevron {
  position: absolute;
  top: 22px;
  right: 15px;
  margin: 10px 0px;
  cursor: pointer;
  width: 10px !important;
  pointer-events: none;
  color: #7e7b7b;
}

.addm-input {
  min-width: 0;
}

.addm-number {
  display: flex;
  flex-direction: row;
}

.addm-contactnum-select-chevron {
  position: absolute;
  top: -3px;
  right: 0px;
  margin: 10px 0px;
  cursor: pointer;
  width: 10px !important;
  pointer-events: none;
  color: #7e7b7b;
}

.addm-select-input {
  min-width: 0 !important;
}

.addm-contact-num {
  flex-grow: 9;
  min-width: 0;
  font-size: 12px !important;
  top: 0px;
  position: relative;
}

.addm-contactnum-select {
  flex-grow: 1;
  margin-right: 1rem;
  width: 85px;
  position: relative;
}

.addm-opacity {
  opacity: 0.2;
}

.addm-search {
  position: absolute;
  top: 25%;
  right: 0;
  text-decoration: underline;
  font: Bold 12px/18px Poppins, sans-serif;
  letter-spacing: 0;
  color: #db7020 !important;
  cursor: pointer;
}

.addm-member {
  font-size: 22px;
  color: #636466;
  font-weight: bold;
  margin-left: 0.3rem;
}

.addm-modal {
  max-width: 950px !important;
}

.addm-datepicker {
  color: #d3d3d3;
  font-size: 14px;
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
}

.addm-not-selected {
  color: #d3d3d3;
}

.addm-gray-ph::placeholder {
  color: #d3d3d3;
}

.addm-postcode {
  width: 100% !important;
}

.addm-county-label {
  font: 16px/25px Poppins, sans-serif;
  font-weight: normal !important;
}

.addm-town-label {
  font: 16px/25px Poppins, sans-serif;
  font-weight: normal !important;
}

.addm-address-label {
  font: 16px/25px Poppins, sans-serif;
  font-weight: normal !important;
}

.addm-datepicker-active {
  color: #495057;
}

.addm-mt-3 {
  margin-top: 3px;
}
