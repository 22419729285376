.section-layout {
  padding: 1em;

  &.patient {
    width: 100%;
    overflow-y: auto;
    padding: 0;

    & > .container {
      background-color: #fff;
      max-width: 1482px;
      margin: auto;
      padding: 0 16px 16px 16px;

      @media (min-width: 768px) {
        padding: 32px;
        padding-top: 48px;
      }

      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.page-container {
  background-color: #f5f7f8;
  position: absolute;
  height: 100dvh;
  min-height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 43px;

  @media (max-width: 1200px) {
    padding-bottom: 72px;
  }
  @media (max-width: 768px) {
    padding-bottom: 82px;
  }
  @media (max-width: 400px) and (max-height: 400px) {
    padding-bottom: 35px;
  }
}

.ant-btn {
  border-radius: 4px;
}

:root {
  --out-of-range-color: #{lighten(#333, 40%)};
  --out-of-range-bg-color: #{lighten(#333, 70%)};

  --active-background: #{darken(#fff, 10%)};
  --active-border: #{darken(#ccc, 12%)};

  --calendar-border: #ddd;
  --cell-border: #ddd;

  --time-selection-color: white;
  --time-selection-bg-color: rgba(0, 0, 0, 0.5);
  --date-selection-bg-color: rgba(0, 0, 0, 0.1);

  --event-bg: var(--lightProductGray);
  --dark-event-bg: var(--productPrimary);
  --event-border: var(--cell-border);
  --event-outline: #3b99fc;
  --event-color: #fff;
  --event-border-radius: 5px;
  --event-padding: 2px 5px;

  --btn-color: #373a3c;
  --btn-bg: #fff;
  --btn-border: #ccc;

  --current-time-color: #cc0000;

  --today-highlight-bg: transparent;

  --fontPrimary: 'Poppins', Helvetica, Arial, sans-serif !important;
  --borderRadius: 4px;

  --fontSizeBody: 13px;
  --lineHeightBody: 23px;

  --fontSizeH1: 30px;
  --lineHeightH1: 2rem;

  --fontSizeH2: 24px;
  --lineHeightH2: 2rem;

  --fontSizeH3: 22px;
  --lineHeightH3: 2rem;

  --fontSizeH4: 16px;
  --lineHeightH4: 2rem;

  --fontSizeH5: 12px;
  --lineHeightH5: 2rem;

  --fontSizeButton: 12px;
  --lineHeightButton: 1.8rem;

  --screen-md-max: 991px;

  --productRed: #ff0000;
  --productRedDark: #990000;
  --productGreen: #09a846;
  --productGreenDark: #045423;
  --productGray: #636466;
  --lightProductGray: #9b9b9b;
  --productIron: #dbdddf;
  --white: #ffffff;
  --black: #000000;
  --color-doveGray: #707070;
  --color-Alabaster: #f8f8f8;
  --welcome-title: 'Let Us Connect You to a Clinical Expert';
  --welcome-weight: 500;

  --border-color-base: #d9d9d9;

  --ant-success-color: #007d1b;
  --ant-warning-color: #a05a00;
  --ant-error-color: #d70015;

  --focusOutline: #3b99fc;
}

/*
/ Focus styles
*/

@mixin outline-focus($offset: 2px) {
  outline-style: auto !important;
  outline-width: 4px !important;
  outline-color: var(--focusOutline) !important;
  outline-offset: $offset !important;
}

%focus-visible {
  @include outline-focus;
}

%focus-visible-inset {
  @include outline-focus(-4px);
}

a,
button,
summary,
* [role='tab'] {
  &:focus-visible {
    @extend %focus-visible;
  }
}

.ant-select-selector,
.ant-input {
  &:focus-within {
    @extend %focus-visible;
  }
}

.ant-dropdown-menu,
.ant-tabs-tabpane,
.ant-table-column-has-sorters {
  &:focus-visible {
    @extend %focus-visible;
  }
}

.ant-form-item-explain,
.ant-form-item-extra {
  color: var(--productGray);
}

.PhoneInputCountrySelect:focus ~ .PhoneInputCountryIcon {
  @extend %focus-visible;
}

.ant-table-column-has-sorters {
  &:focus-visible {
    @extend %focus-visible-inset;
  }
}

@mixin high-contrast-rules {
  --productPrimary: #000;
  --productPrimaryHover: #444;
  --productPrimaryDark: #000;
  --background-image: linear-gradient(-40deg, #fff 35%, #444 35%);

  --ant-primary-color: var(--productPrimary);
  --ant-primary-color-hover: var(--productPrimaryHover);
  --ant-primary-5: var(--productPrimaryHover);
  --ant-primary-color-active: #000;
  --ant-primary-color-outline: purple;

  --ant-success-color: darkgreen;
  --ant-success-color-hover: darkgreen;
  --ant-success-color-active: darkgreen;
  --ant-success-color-outline: darkgreen;
  --ant-success-color-deprecated-bg: #fff;
  --ant-success-color-deprecated-border: darkgreen;
  --ant-error-color: darkred;
  --ant-error-color-hover: darkred;
  --ant-error-color-active: darkred;
  --ant-error-color-outline: darkred;
  --ant-error-color-deprecated-bg: #fff;
  --ant-error-color-deprecated-border: darkred;
  --ant-warning-color: orange;
  --ant-warning-color-hover: orange;
  --ant-warning-color-active: orange;
  --ant-warning-color-outline: orange;
  --ant-warning-color-deprecated-bg: #fff;
  --ant-warning-color-deprecated-border: orange;
  --ant-info-color: darkblue;
  --ant-info-color-deprecated-bg: #fff;
  --ant-info-color-deprecated-border: darkblue;

  button,
  a,
  input,
  [tabindex='0'],
  a {
    &:not([disabled='true']) {
      transition: none !important;

      &:hover {
        outline: 2px solid #888;
      }
      &:focus {
        outline: 4px solid purple;
        outline-offset: 2px;
      }
      &:active {
        outline: 4px solid magenta;
        outline-offset: 2px;
      }
    }
  }

  [disabled='true'] {
    color: #999 !important;
  }

  a {
    text-decoration: underline;
    color: blue;
  }

  .nav-link {
    &.active {
      text-decoration: underline;
      text-decoration-thickness: 3px;
    }
  }

  .ant-dropdown-trigger {
    text-decoration: none !important;
  }

  .ant-alert {
    border-width: 4px !important;
  }

  button[disabled] {
    cursor: not-allowed;
    color: #000 !important;
  }
}

[data-contrast='high'] {
  @include high-contrast-rules;
}

@media screen and (prefers-contrast: more) {
  body:not([data-contrast='normal']) {
    @include high-contrast-rules;
  }
}
