@import '../../../node_modules/bootstrap/scss/reboot';
@import '../../../node_modules/bootstrap/scss/type';

@import '../../../node_modules/bootstrap/scss/bootstrap-utilities.scss';

@import '../../../node_modules/bootstrap/scss/accordion';
@import '../../../node_modules/bootstrap/scss/alert';
@import '../../../node_modules/bootstrap/scss/badge';
@import '../../../node_modules/bootstrap/scss/breadcrumb';
@import '../../../node_modules/bootstrap/scss/button-group';
@import '../../../node_modules/bootstrap/scss/buttons';
@import '../../../node_modules/bootstrap/scss/card';
@import '../../../node_modules/bootstrap/scss/carousel';
@import '../../../node_modules/bootstrap/scss/close';
@import '../../../node_modules/bootstrap/scss/containers';
@import '../../../node_modules/bootstrap/scss/dropdown';
@import '../../../node_modules/bootstrap/scss/forms';
@import '../../../node_modules/bootstrap/scss/grid';
@import '../../../node_modules/bootstrap/scss/helpers';
@import '../../../node_modules/bootstrap/scss/images';
@import '../../../node_modules/bootstrap/scss/list-group';
@import '../../../node_modules/bootstrap/scss/modal';
@import '../../../node_modules/bootstrap/scss/nav';
@import '../../../node_modules/bootstrap/scss/navbar';
@import '../../../node_modules/bootstrap/scss/offcanvas';
@import '../../../node_modules/bootstrap/scss/pagination';
@import '../../../node_modules/bootstrap/scss/popover';
@import '../../../node_modules/bootstrap/scss/progress';
@import '../../../node_modules/bootstrap/scss/spinners';
@import '../../../node_modules/bootstrap/scss/tables';
@import '../../../node_modules/bootstrap/scss/toasts';
@import '../../../node_modules/bootstrap/scss/tooltip';
@import '../../../node_modules/bootstrap/scss/transitions';

// if --productPrimary is not defined, use the default orange (i.e. when in vl-admin)
* {
  --hbs-orange: #db6e20;
  --productPrimary: var(var(--productPrimary), var(--hbs-orange));
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--productPrimary);
  --bs-btn-border-color: var(--productPrimary);
  --bs-btn-hover-bg: var(--productPrimary);
  --bs-btn-hover-border-color: var(--productPrimary);
  --bs-btn-active-bg: var(--productPrimary);
  --bs-btn-active-border-color: var(--productPrimary);
  --bs-btn-disabled-bg: var(--productPrimary);
  --bs-btn-disabled-border-color: var(--productPrimary);
}
